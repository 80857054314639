import * as React from 'react';                                                                                                  
import * as ReactDOM from 'react-dom';

import {useRef, useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';

import Icon from '@mdi/react';
import { mdiPlay, mdiPause, mdiLoading, mdiMagnify, mdiBookshelf, mdiHome, mdiPlaylistPlay} from '@mdi/js';

function HBox({children}) {
  return <div className="flex flex-row">
	{children}
  </div>
}

function VBox({children}) {
  return <div className="flex flex-col">
	{children}
  </div>
}

function Container({children, styles}) {
  const txt_styles = `container mx-auto ${styles||''}`
  return <div className={txt_styles}>
    <VBox>{children}</VBox>
  </div>
}

function Navbar({children}) {
  return <div className="fixed top-0 inset-x-0">
    <Container>
      <div className="flex h-14 items-center justify-between">
        <div className="flex items-center gap-4">
          ODOOP
        </div>
        <div className="flex items-center gap-4">
          <div>Extra</div>
          <div>About</div>
          <div>Login</div>
        </div>
      </div>
    </Container>
  </div>
}

function App() {
  return <div>
    <Navbar></Navbar>
    <Container styles="pt-14">
      <h1 className="text-sm">Odoo Plus Temporary Offline</h1>
    </Container>
  </div>
}

document.addEventListener('DOMContentLoaded', () => {
    // const root_el = document.getElementById('root')
    const root = createRoot(document.body)
    root.render(<App />)
})
